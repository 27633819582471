<template>
  <div>
    <b-form id="test-link-account-api-form" name="test-link-account-api-form" @submit.prevent="onSubmitLinkAccountTestApi" autocomplete="off" novalidate>
      <b-form-row>
        <b-col>
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                id="link-account-api-endpoint-to-test"
                :class="{
                  'is-invalid': (!testAPIModel.linkAccountEndPoint && formSubmitted) || (!validateUrl(testAPIModel.linkAccountEndPoint) && formSubmitted),
                }"
                type="text"
                placeholder=" "
                v-model="testAPIModel.linkAccountEndPoint"
                required
              ></b-form-input>
              <label for="link-account-api-endpoint-to-test">Link account API endpoint to test</label>
              <b-form-invalid-feedback class="d-block" v-if="!testAPIModel.linkAccountEndPoint && formSubmitted"
                >Link account API endpoint to test required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                class="d-block"
                v-if="testAPIModel.linkAccountEndPoint && formSubmitted && !validateUrl(testAPIModel.linkAccountEndPoint)"
                >Invalid url.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                id="link-account-unlink-api-endpoint-to-test"
                :class="{
                  'is-invalid':
                    (!testAPIModel.unLinkAccountEndPoint && unLinkFormSubmitted) || (!validateUrl(testAPIModel.unLinkAccountEndPoint) && unLinkFormSubmitted),
                }"
                type="text"
                placeholder=" "
                v-model="testAPIModel.unLinkAccountEndPoint"
                required
              ></b-form-input>
              <label for="link-account-unlink-api-endpoint-to-test">Unlink account API endpoint to test</label>
              <b-form-invalid-feedback class="d-block" v-if="!testAPIModel.unLinkAccountEndPoint && unLinkFormSubmitted"
                >Unlink account API endpoint to test required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                class="d-block"
                v-if="testAPIModel.unLinkAccountEndPoint && unLinkFormSubmitted && !validateUrl(testAPIModel.unLinkAccountEndPoint)"
                >Invalid url.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <TestWithSecurityMethod :testAPIModel="testAPIModel" :formSubmitted="formSubmitted" />
      <LinkAccountFormFields :fields="testAPIModel.fields" :formSubmitted="formSubmitted" />
      <template v-if="this.testAPIModel.isPropertyDependent">
        <b-form-row>
          <b-col>
            <b-form-group>
              <div class="floating-select-field">
                <v-select
                  label="name"
                  :id="`street-name`"
                  :filterable="false"
                  :class="{
                    'is-value-exist': testAPIModel.streetName || focusedStreetName,
                    'is-invalid': !testAPIModel.streetName && formSubmitted,
                  }"
                  :options="streetNames"
                  v-model="testAPIModel.streetName"
                  @open="focusedStreetName = true"
                  @close="focusedStreetName = false"
                  @search="onSearchStreetNames"
                  @input="getStreetNumbers($event)"
                >
                  <template slot="no-options" slot-scope="{ search }">
                    {{
                      search && search.trim()
                        ? `No street names found.`
                        : `Start typing your street name, and select it from the
                      dropdown.`
                    }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.name }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.name }}
                  </template>
                </v-select>
                <label :for="`street-name`">Street name</label>
                <b-form-invalid-feedback class="d-block" v-if="!testAPIModel.streetName && formSubmitted">Street name required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="6">
            <b-form-group>
              <div class="floating-select-field">
                <select
                  v-model="testAPIModel.streetNumber"
                  @input="testAPIModel.streetNumber = $event.target.value"
                  @change="getUnitNumbers($event.target.value)"
                  :id="`street-number`"
                  :disabled="!testAPIModel.streetName"
                  class="form-control"
                  :class="{
                    'is-value-exist': testAPIModel.streetNumber,
                    'is-invalid': !testAPIModel.streetNumber && formSubmitted,
                  }"
                >
                  <option v-for="(streetNumber, si) in streetNumbers" :value="streetNumber" :key="si">
                    {{ streetNumber }}
                  </option>
                </select>
                <label :for="`street-number`">Street number</label>
                <b-form-invalid-feedback class="d-block" v-if="!testAPIModel.streetNumber && formSubmitted">Street number required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <div class="floating-select-field" v-if="unitNumbers">
                <select
                  v-model="testAPIModel.unitNumber"
                  @input="testAPIModel.unitNumber = $event.target.value"
                  @change="testAPIModel.unitNumber = $event.target.value"
                  :id="`unit-number`"
                  :disabled="!testAPIModel.streetNumber || !unitNumbers.length"
                  class="form-control"
                  :class="{
                    'is-value-exist': testAPIModel.unitNumber,
                    'is-invalid': unitNumbers.length && !testAPIModel.unitNumber && formSubmitted,
                  }"
                >
                  <option v-for="(unitNumber, ui) in unitNumbers" :value="unitNumber" :key="ui">
                    {{ unitNumber }}
                  </option>
                </select>
                <label :for="`unit-number`">Apt / unit number</label>
                <b-form-invalid-feedback class="d-block" v-if="unitNumbers.length && !testAPIModel.unitNumber && formSubmitted"
                  >Apt / unit number required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
      </template>
      <b-form-row class="mt-3">
        <b-col>
          <b-button type="submit" v-activeBlur variant="secondary" :disabled="saveLoadingIcon"
            >Link <b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
          ></b-button>
          <b-button
            type="button"
            v-activeBlur
            variant="secondary"
            class="ml-3"
            @click="onSubmitUnlinkAccountTestApi"
            :disabled="!accessToken || !testAPIModel.unLinkAccountEndPoint || saveUnlinkLoadingIcon"
            >Unlink <b-spinner v-if="saveUnlinkLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
          ></b-button>
          <b-button type="button" v-activeBlur class="ml-3" @click="onResetTestAPIForm" variant="outline-secondary">Cancel</b-button>
        </b-col>
      </b-form-row>
    </b-form>
    <TestAPITabs :apiResponse="apiResponse" :tabs="responseTabs" />
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { useValidateFields } from '@/composables/useValidateFields'
import { DISPLAY_MESSAGES } from '../../../utilities/constants'
export default {
  name: 'TestLinkAccountAPIForm',
  props: ['linkAccountDetails'],
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, scrollToErrorMessage }
  },
  data() {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      formSubmitted: false,
      unLinkFormSubmitted: false,
      saveLoadingIcon: false,
      saveUnlinkLoadingIcon: false,
      streetNames: [],
      streetNumbers: [],
      unitNumbers: [],
      cloneTestAPIModel: null,
      testAPIModel: {
        linkAccountEndPoint: null,
        unLinkAccountEndPoint: null,
        testWithSecurityMethod: false,
        securityMethodId: null,
        fields: [],
        streetName: null,
        streetNumber: null,
        unitNumber: null,
        isPropertyDependent: true,
      },
      focusedStreetName: false,
      apiResponse: null,
      accessToken: null,
      responseTabs: ['APIResponse', 'Exceptions', 'Output'],
    }
  },
  components: {
    LinkAccountFormFields: () => import('./LinkAccountFormFields.vue'),
    TestAPITabs: () => import('../../common/test-api-response-tabs/TestAPITabs.vue'),
    TestWithSecurityMethod: () => import('@/components/common/TestWithSecurityMethod.vue'),
  },
  mounted() {
    this.assignTestAPIData()
  },
  methods: {
    assignTestAPIData() {
      if (this.linkAccountDetails) {
        this.testAPIModel.linkAccountEndPoint = this.linkAccountDetails.authEndPoint
        this.testAPIModel.unLinkAccountEndPoint = this.linkAccountDetails.unLinkEndPoint
        this.testAPIModel.fields = cloneDeep(this.linkAccountDetails.fields)
        this.testAPIModel.isPropertyDependent = this.linkAccountDetails.isForEachProperty
        this.testAPIModel.testWithSecurityMethod = cloneDeep(this.linkAccountDetails.applySecurityMethod)
        this.testAPIModel.securityMethodId = cloneDeep(this.linkAccountDetails.securityMethodId)
        this.cloneTestAPIModel = cloneDeep(this.testAPIModel)
      }
    },
    onSearchStreetNames(search, loading) {
      search = search ? search.trim() : search
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      const postObj = {
        tenantId: 0,
        streetName: search,
        streetNumber: '',
      }
      vm.$store.dispatch('widgets/getStreetNames', postObj).then((res) => {
        vm.streetNames = res && res.data ? res.data : []
        loading(false)
      })
    }, 50),
    async getStreetNumbers(streetName) {
      if (streetName) {
        const postObj = {
          tenantId: 0,
          streetName: streetName,
          streetNumber: '',
        }
        this.testAPIModel.streetNumber = ''
        this.testAPIModel.unitNumber = ''
        this.unitNumbers = []
        this.streetNumbers = []
        const res = await this.$store.dispatch('widgets/getStreetNumbers', postObj)
        this.streetNumbers = res && res.data ? res.data : []
      } else {
        this.testAPIModel.streetNumber = ''
        this.testAPIModel.unitNumber = ''
        this.unitNumbers = []
        this.streetNumbers = []
      }
    },
    async getUnitNumbers(streetNumber) {
      if (streetNumber) {
        const postObj = {
          tenantId: 0,
          streetName: this.testAPIModel.streetName,
          streetNumber: streetNumber,
        }
        this.testAPIModel.unitNumber = ''
        this.unitNumbers = []
        const res = await this.$store.dispatch('widgets/getUnitNumbers', postObj)
        this.unitNumbers = res && res.data ? res.data : []
      }
    },
    onResetTestAPIForm() {
      this.formSubmitted = false
      this.unLinkFormSubmitted = false
      this.streetNames = []
      this.streetNumbers = []
      this.unitNumbers = []
      this.testAPIModel = cloneDeep(this.cloneTestAPIModel)
    },
    validateLinkAccountTestAPIForm() {
      const linkAccountEndPoint = this.validateUrl(this.testAPIModel.linkAccountEndPoint)
      const fields = !!this.testAPIModel.fields.length
      const securityMethod = this.testAPIModel.testWithSecurityMethod ? !!this.testAPIModel.securityMethodId : true
      const fieldsError = this.testAPIModel.fields.some((field) => !field.fieldValue)
      if (!fieldsError) {
        this.testAPIModel.fields.forEach((field) => {
          if (field.fieldType === 'Date') {
            field.fieldValue = moment(field.fieldValue).format('MM-DD-YYYY')
          }
        })
      }
      const streetNameError = this.testAPIModel.isPropertyDependent ? !!this.testAPIModel.streetName : true
      const streetNumberError = this.testAPIModel.isPropertyDependent ? !!this.testAPIModel.streetNumber : true
      const unitNumberError = this.testAPIModel.isPropertyDependent && this.unitNumbers.length ? !!this.testAPIModel.unitNumber : true
      this.scrollToErrorMessage()
      return linkAccountEndPoint && fields && securityMethod && !fieldsError && streetNameError && streetNumberError && unitNumberError
    },
    onSubmitLinkAccountTestApi() {
      this.formSubmitted = true
      this.apiResponse = null
      this.accessToken = null
      if (this.validateLinkAccountTestAPIForm()) {
        this.saveLoadingIcon = true
        this.$store
          .dispatch('settings/linkAccountTestApi', this.testAPIModel)
          .then((res) => {
            this.saveLoadingIcon = false
            this.apiResponse = res.data
            this.accessToken = res.data.accessToken
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          })
      }
    },
    onSubmitUnlinkAccountTestApi() {
      this.unLinkFormSubmitted = true
      this.apiResponse = null
      const unLinkAccountEndPoint = this.validateUrl(this.testAPIModel.unLinkAccountEndPoint)
      if (unLinkAccountEndPoint) {
        this.saveUnlinkLoadingIcon = true
        const postObj = {
          unLinkAccountEndPoint: this.testAPIModel.unLinkAccountEndPoint,
          securityMethodId: this.testAPIModel.securityMethodId,
          accessToken: this.accessToken,
        }
        this.$store
          .dispatch('settings/unlinkAccountTestApi', postObj)
          .then((res) => {
            this.saveUnlinkLoadingIcon = false
            this.apiResponse = res.data
          })
          .catch(() => {
            this.saveUnlinkLoadingIcon = false
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          })
      }
    },
  },
}
</script>
